import { Col, Container, Nav, Row, Tab, Table } from "react-bootstrap"
import { Link, withI18next } from "gatsby-plugin-i18next"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { I18n } from "react-i18next"
import Img from "gatsby-image"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import { faLightbulb } from "@fortawesome/free-solid-svg-icons"
import { graphql } from "gatsby"

class Cooperative extends React.Component {
  render() {
    return (
      <I18n>
        {t => (
          <Layout>
            <SEO title={t("what_is_coop")} />
            <div className="section bg-dark-blue mt-4">
              <Container className="height-md-30">
                <Row>
                  <Col md="8" lg="7" xl="6">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb p-0 bg-dark-blue bg-transparent">
                        <li className="breadcrumb-item">
                          <Link to="/" className="text-white">
                            {t("home")}
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/cooperative" className="text-white">
                            {t("what_is_coop")}
                          </Link>
                        </li>
                      </ol>
                    </nav>
                  </Col>
                  <Col md="12">
                    <h1 className="display-5 text-white">
                      {t("what_is_coop")} <FontAwesomeIcon icon={faLightbulb} />
                    </h1>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="content">
              <div className="section py-3">
                <Container>
                  <Row>
                    <Col>
                      <h2>{t("what_is_coop")}</h2>
                    </Col>
                  </Row>
                  <Row className="justify-content-xs-center">
                    <Col
                      xs={{ span: 12, order: 2 }}
                      sm={{ span: 12, order: 2 }}
                      lg={{ span: 7, order: 1 }}
                    >
                      <p>
                        {t("coop_desc_1")}{" "}
                        <a href="#">{t("coop_desc_link_1")}</a>{" "}
                        {t("coop_desc_2")}
                      </p>
                      <p>
                        {t("coop_desc_1")}{" "}
                        <a href="#">{t("coop_desc_link_2")}</a>{" "}
                        {t("coop_desc_3")}
                      </p>
                      <p>{t("coop_desc_4")}</p>
                    </Col>
                    <Col
                      xs={{ span: 12, order: 1 }}
                      sm={{ span: 12, order: 1 }}
                      lg={{ span: 5, order: 2 }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Img
                        fluid={
                          this.props.data.cooperative.childImageSharp.fluid
                        }
                        className="rounded img-thumbnail"
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="section py-3">
                <Container>
                  <Row className="justify-content-md-center">
                    <Col>
                      <Table responsive="lg" className="coop-compare">
                        <thead>
                          <tr>
                            <th>{t("coop_business_model")}</th>
                            <th></th>
                            <th>{t("trad_business_model")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="align-middle">
                              {t("coop_ownership")}
                            </td>
                            <td className="align-middle font-weight-bold">
                              {t("ownership")}
                            </td>
                            <td>{t("trad_ownership")}</td>
                          </tr>
                          <tr>
                            <td className="align-middle">
                              {t("coop_management")}
                            </td>
                            <td className="align-middle font-weight-bold">
                              {t("management")}
                            </td>
                            <td className="align-middle">
                              {t("trad_management")}
                            </td>
                          </tr>
                          <tr>
                            <td className="align-middle">
                              {t("coop_control")}
                            </td>
                            <td className="align-middle font-weight-bold">
                              {t("control")}
                            </td>
                            <td className="align-middle">
                              {t("trad_control")}
                            </td>
                          </tr>
                          <tr>
                            <td className="align-middle">
                              {t("coop_benefits")}
                            </td>
                            <td className="align-middle font-weight-bold">
                              {t("benefits")}
                            </td>
                            <td className="align-middle">
                              {t("trad_benefits")}
                            </td>
                          </tr>
                          <tr>
                            <td className="align-middle">
                              {t("coop_survival")}
                            </td>
                            <td className="align-middle font-weight-bold">
                              {t("survival")}
                            </td>
                            <td className="align-middle">
                              {t("trad_survival")}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="section bg-dark-blue py-5">
                <Container>
                  <Row className="no-gutters justify-content-center">
                    <Col lg="12">
                      <h3>{t("coop_principle")}</h3>
                    </Col>
                    <Col lg="6">
                      <ul>
                        <li>{t("coop_principle_1")}</li>
                        <li>{t("coop_principle_2")}</li>
                        <li>{t("coop_principle_3")}</li>
                        <li>{t("coop_principle_4")}</li>
                      </ul>
                    </Col>
                    <Col lg="6">
                      <ul>
                        <li>{t("coop_principle_5")}</li>
                        <li>{t("coop_principle_6")}</li>
                        <li>{t("coop_principle_7")}</li>
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="section py-5">
                <Container>
                  <Row>
                    <Col>
                      <h2>{t("coop_type")}</h2>
                      <Tab.Container defaultActiveKey="worker">
                        <div className="tab-container">
                          <Nav fill className="tab-nav">
                            <Nav.Item>
                              <Nav.Link eventKey="worker">
                                {t("coop_type_worker")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="consumer">
                                {t("coop_type_consumer")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="producer">
                                {t("coop_type_producer")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="stakeholder">
                                {t("coop_type_stakeholder")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="credit">
                                {t("coop_type_credit_savings")}
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="worker">
                              <h4>{t("coop_type_worker_title")}</h4>
                              <p>{t("coop_type_worker_desc")}</p>
                            </Tab.Pane>
                            <Tab.Pane eventKey="consumer">
                              <h4>{t("coop_type_consumer_title")}</h4>
                              <p>{t("coop_type_consumer_desc")}</p>
                            </Tab.Pane>
                            <Tab.Pane eventKey="producer">
                              <h4>{t("coop_type_producer_title")}</h4>
                              <p>{t("coop_type_producer_desc")}</p>
                            </Tab.Pane>
                            <Tab.Pane eventKey="stakeholder">
                              <h4>{t("coop_type_stakeholder_title")}</h4>
                              <p>{t("coop_type_stakeholder_desc")}</p>
                            </Tab.Pane>
                            <Tab.Pane eventKey="credit">
                              <h4>{t("coop_type_credit_savings_title")}</h4>
                              <p>{t("coop_type_credit_savings_desc")}</p>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </Tab.Container>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="section py-3">
                <Container>
                  <Row>
                    <Col>
                      <Tab.Container defaultActiveKey="home">
                        <div className="tab-container">
                          <Nav fill className="tab-nav">
                            <Nav.Item>
                              <Nav.Link eventKey="home">
                                {t("why_how_coop")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="resources">
                                {t("why_how_coop_resources_title")}
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="home">
                              <h4>{t("why_how_coop")}</h4>
                              <p>{t("why_how_coop_desc")}</p>
                            </Tab.Pane>
                            <Tab.Pane eventKey="resources">
                              <h4>{t("why_how_coop_resources")}</h4>
                              <ul>
                                <li>
                                  <p>{t("why_how_coop_resources_1")}</p>
                                  <p>
                                    <a
                                      href="http://www.ilo.org/dyn/natlex/natlex4.detail?p_lang=en&p_isn=61855&p_country=MNG&p_count=137&p_classification=11&p_classcount=3"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t("english")}
                                    </a>
                                  </p>
                                  <p>
                                    <a
                                      href="https://www.legalinfo.mn/law/details/528"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t("mongolian")}
                                    </a>
                                  </p>
                                </li>
                                <li>
                                  <p>{t("why_how_coop_resources_2")}</p>
                                  <p>
                                    <a
                                      href="http://www.frc.mn/en/djza?category=104"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t("english")}
                                    </a>
                                  </p>
                                  <p>
                                    <a
                                      href="https://www.legalinfo.mn/law/details/8772"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t("mongolian")}
                                    </a>
                                  </p>
                                </li>
                                <li>
                                  <a
                                    href="https://drive.google.com/file/d/1ggHK_CoIf5waek357ThN1m6WTz7h0utD/view"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {t("why_how_coop_resources_3")}
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="https://www.ica.coop/en"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {t("why_how_coop_resources_4")}
                                  </a>
                                </li>
                                <li>
                                  <Link to="/mctic-services">
                                    {t("why_how_coop_resources_5")}
                                  </Link>
                                </li>
                              </ul>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </Tab.Container>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="section py-3">
                <Container>
                  <Row className="justify-content-center align-items-center">
                    <Col lg="12">
                      <h2>{t("coop_history")}</h2>
                      <p className="text-justify">{t("coop_history_desc")}</p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Layout>
        )}
      </I18n>
    )
  }
}

export default withI18next()(Cooperative)

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
    cooperative: file(relativePath: { eq: "cooperative/coop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
